import { Auth } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import { Table } from '~/components/Table';
import { useTranslatePermissions } from '~/hooks/useTranslatePermissions';
import type { TUserGroup } from './types';
import type { PermissionTypeDto } from '~/lib/dtos/PermissionsDto';

export const fetchGroupPermissionsRequest = async (groupName: TUserGroup) => {
  const session = await Auth.currentSession();

  const jwtToken = session.getIdToken().getJwtToken();

  const res = await fetch(
    `${
      import.meta.env.VITE_REACT_APP_DOT_API_ENDPOINT
    }/users/groups/${groupName.toLowerCase()}`,
    { headers: { Authorization: `Bearer ${jwtToken}` } }
  );

  if (!res.ok) {
    throw new Error("Couldn't fetch group permissions.");
  }

  return res.json();
};

export const fetchAllPermissionsRequest = async () => {
  const session = await Auth.currentSession();

  const jwtToken = session.getIdToken().getJwtToken();

  const res = await fetch(
    `${
      import.meta.env.VITE_REACT_APP_DOT_API_ENDPOINT
    }/users/groups/permissions`,
    { headers: { Authorization: `Bearer ${jwtToken}` } }
  );

  if (!res.ok) {
    throw new Error("Couldn't fetch all permissions.");
  }

  return res.json();
};

type PermissionMapType = {
  [key in PermissionTypeDto | 'CREATE_EDIT_USER']:
    | PermissionTypeDto
    | 'CREATE_EDIT_USER';
};

const permissionMap: PermissionMapType = {
  VIEW_USERS: 'VIEW_USERS',
  CREATE_USER: 'CREATE_EDIT_USER',
  EDIT_FULLNAME_USER: 'CREATE_EDIT_USER',
  CHANGE_GROUP: 'CREATE_EDIT_USER',
  READ_DATA: 'READ_DATA',
  REFUND_TICKET: 'REFUND_TICKET',
  SEND_RECIPT: 'SEND_RECIPT',
  BLOCK_UNBLOCK_CUSTOMER: 'BLOCK_UNBLOCK_CUSTOMER',
  RESEND_TICKET: 'RESEND_TICKET',
  RESEND_PASSWORD_USER: 'RESEND_PASSWORD_USER',
  DEACTIVATE_ACTIVATE_USER: 'DEACTIVATE_ACTIVATE_USER',
  DELETE_USER: 'DELETE_USER',
  VIEW_AUDIT_LOG: 'VIEW_AUDIT_LOG',
  UPLOAD_INSTRUCTIONS: 'UPLOAD_INSTRUCTIONS',
  CREATE_EDIT_USER: 'CREATE_EDIT_USER',
  MANAGE_BULLETINS: 'MANAGE_BULLETINS',
  VIEW_DASHBOARD: 'VIEW_DASHBOARD',
  MOVE_DSB_QUEUE: 'MOVE_DSB_QUEUE',
  MOVE_DSB_REFUND_QUEUE: 'MOVE_DSB_REFUND_QUEUE',
  DOWNLOAD_DATA: 'DOWNLOAD_DATA',
  UNBLOCK_AND_DECREASE_WARNINGS_PER_OPERATORS:
    'UNBLOCK_AND_DECREASE_WARNINGS_PER_OPERATORS',
  DECREASE_WARNING: 'DECREASE_WARNING',
};

const getMappedPermission = (
  permission: PermissionTypeDto | 'CREATE_EDIT_USER'
): PermissionTypeDto | 'CREATE_EDIT_USER' => {
  return permissionMap[permission];
};

interface PermissionsTableProps {
  groupPermissions: (PermissionTypeDto | 'CREATE_EDIT_USER')[];
  allPermissions: (PermissionTypeDto | 'CREATE_EDIT_USER')[];
}

const PermissionsTable: React.FC<PermissionsTableProps> = ({
  groupPermissions = [],
  allPermissions = [],
}: PermissionsTableProps) => {
  const { translatePermissions } = useTranslatePermissions();
  const { t } = useTranslation();
  let uniquePermissions = [...new Set(allPermissions)];

  if (
    groupPermissions.includes('CREATE_USER') &&
    groupPermissions.includes('EDIT_FULLNAME_USER') &&
    groupPermissions.includes('CHANGE_GROUP')
  ) {
    uniquePermissions = uniquePermissions.filter(
      (permission) =>
        permission !== 'CREATE_USER' &&
        permission !== 'EDIT_FULLNAME_USER' &&
        permission !== 'CHANGE_GROUP'
    );
    uniquePermissions.splice(5, 0, 'CREATE_EDIT_USER');
  }

  if (
    !groupPermissions.includes('CREATE_USER') &&
    !groupPermissions.includes('EDIT_FULLNAME_USER') &&
    !groupPermissions.includes('CHANGE_GROUP')
  ) {
    uniquePermissions = uniquePermissions.filter(
      (permission) =>
        permission !== 'CREATE_USER' &&
        permission !== 'EDIT_FULLNAME_USER' &&
        permission !== 'CHANGE_GROUP'
    );
    uniquePermissions.splice(5, 0, 'CREATE_EDIT_USER');
  }

  const columns = uniquePermissions.map((permission) =>
    translatePermissions(getMappedPermission(permission))
  );
  const permissionRow = uniquePermissions.map((permission) => {
    const mappedPermission = getMappedPermission(permission);
    if (mappedPermission === 'CREATE_EDIT_USER') {
      if (groupPermissions.includes('CREATE_USER')) {
        return '✔️';
      }
      return '';
    } else {
      if (groupPermissions.includes(permission)) {
        return '✔️';
      }
      return '';
    }
  });
  const permissionRows = [{ ...permissionRow, id: '1' }];

  return (
    <Table
      label={t('permissions.permissions')}
      columns={columns}
      rows={permissionRows}
    />
  );
};

export default PermissionsTable;
