import { Table } from 'flowbite-react';
import { useTranslation } from 'react-i18next';
import { ModalCell } from '../CustomerNotesTable';
import type { Nullable } from 'vitest';
import Header from '~/components/Header';
import { formatDate } from '~/lib/utils/formatDates/formatDates';
import type { BlockReasonDTO } from '~/lib/dtos/common/BlockReasonDTO';
import { useTranslateBlockReasons } from '~/hooks/useTranslateBlockReasons';
import Pagination from '~/features/search/components/Pagination';
import { useEffect, useState } from 'react';

type BlockType = 'permanent' | 'temporary' | 'unblock' | 'decreased-warning';

type BlockRecord = {
  type: BlockType;
  id: string;
  createdAt: string;
  blockReason: BlockReasonDTO;
  blockadeStartAt: Nullable<string>;
  blockadeEndAt: Nullable<string>;
  note: string;
  user: string;
};

export type BlockadesTableProps = {
  blockades: BlockRecord[];
};

const ITEMS_PER_PAGE = 10;

export const BlockadesTable = ({ blockades }: BlockadesTableProps) => {
  const { t } = useTranslation();

  const [page, setPage] = useState(1);
  const [paginatedBlockades, setPaginatedBlockades] = useState(
    blockades.slice(0, ITEMS_PER_PAGE)
  );
  const availablePages = Math.ceil(blockades.length / ITEMS_PER_PAGE);
  const count = blockades.length;

  const handlePageClick = (event: { selected: number }) => {
    setPage(event.selected + 1);
  };

  useEffect(() => {
    const startIndex = (page - 1) * ITEMS_PER_PAGE;
    const endIndex = startIndex + ITEMS_PER_PAGE;
    setPaginatedBlockades(blockades.slice(startIndex, endIndex));
  }, [blockades, page]);

  return (
    <div className="overflow-x-auto">
      <Header className="mt-auto mb-2">{t('customer.blocking')}</Header>
      <Table className="shadow-lg">
        <TableBody blockades={paginatedBlockades} />
      </Table>
      {blockades.length > 10 && (
        <Pagination
          currentPage={page - 1}
          onPageChange={handlePageClick}
          pageCount={availablePages}
          resultsNumber={count}
        />
      )}
    </div>
  );
};

const TableBody = ({ blockades }: BlockadesTableProps) => {
  const { t } = useTranslation();
  const { translateBlockType } = useTranslateBlockTypes();
  const { translateBlockReasons } = useTranslateBlockReasons();

  const headers: string[] = [
    t('tables.note-type'),
    t('tables.blocking-reason'),
    t('tables.created'),
    t('customer.block-start'),
    t('customer.block-end'),
    t('customer.note'),
    t('customer.user'),
  ];

  if (blockades.length === 0) {
    return (
      <Table.Head className="flex justify-center text-center">
        <Table.HeadCell>
          {t('common.no-orders-for-this-search-criteria')}
        </Table.HeadCell>
      </Table.Head>
    );
  }

  const displayUser = (user: string) => {
    if (user === 'SYSTEM') {
      return t('common.system');
    } else if (user === 'SYSTEM_MANUAL') {
      return t('common.system-manual');
    }

    return user;
  };
  return (
    <>
      <Table.Head className="bg-gray-100">
        {headers.map((header) => (
          <Table.HeadCell key={header}>
            <div className="flex justify-center my-auto text-center align-middle">
              {header}
            </div>
          </Table.HeadCell>
        ))}
      </Table.Head>
      <Table.Body className="divide-y">
        {blockades.map(
          ({
            id,
            blockadeEndAt,
            blockadeStartAt,
            createdAt,
            note,
            type,
            user,
            blockReason,
          }) => {
            return (
              <Table.Row
                key={id}
                className="dark:border-gray-700 dark:bg-gray-800 dark:text-white font-medium text-gray-900 bg-white"
              >
                <Table.Cell className="text-center border-2 border-gray-100">
                  {translateBlockType(type)}
                </Table.Cell>
                <Table.Cell className="text-center border-2 border-gray-100">
                  {translateBlockReasons(blockReason)}
                </Table.Cell>
                <Table.Cell className="text-center border-2 border-gray-100">
                  {createdAt ? formatDate(createdAt) : '-'}
                </Table.Cell>
                <Table.Cell className="text-center border-2 border-gray-100">
                  {blockadeStartAt ? formatDate(blockadeStartAt) : '-'}
                </Table.Cell>
                <Table.Cell className="text-center border-2 border-gray-100">
                  {blockadeEndAt ? formatDate(blockadeEndAt) : '-'}
                </Table.Cell>
                <ModalCell content={note} />
                <Table.Cell className="text-center border-2 border-gray-100">
                  {displayUser(user)}
                </Table.Cell>
              </Table.Row>
            );
          }
        )}
      </Table.Body>
    </>
  );
};

const useTranslateBlockTypes = () => {
  const { t } = useTranslation();

  const blockTypesMap: Record<BlockType, string> = {
    permanent: t('customer.permanent'),
    temporary: t('customer.temporary'),
    unblock: t('customer.unblock'),
    'decreased-warning': t('customer.decreased-warning'),
  };

  const translateBlockType = (blockType: BlockType) => {
    return blockTypesMap[blockType];
  };

  return { translateBlockType };
};
