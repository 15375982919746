import Header from '../Header';
import type { ModalProps } from './Modal';
import { Modal } from './Modal';
import ButtonWithLoading from '../ButtonWithLoading';
import { Controller, useForm } from 'react-hook-form';
import z from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useTranslation } from 'react-i18next';
import { useEffect, type ReactNode } from 'react';

const NOTE_MAX_LENGTH = 500;
const NOTE_MIN_LENGTH = 10;

const useGenerateSchema = () => {
  const { t } = useTranslation();

  return z.object({
    note: z
      .string({ required_error: t('common.min-10-chars') })
      .min(NOTE_MIN_LENGTH, { message: t('common.min-10-chars') }),
  });
};

type NoteModalProps = {
  title: string;
  onSubmit: (note: string) => void;
  isLoading: boolean;
  submitText: string;
  children?: ReactNode;
  maxLength?: number;
  prefilledText?: string;
} & Omit<ModalProps, 'children'>;

export const NoteModal = ({
  isVisible,
  toggleVisibility,
  onSubmit,
  isLoading,
  submitText,
  title,
  children,
  maxLength,
  prefilledText,
}: NoteModalProps) => {
  const { t } = useTranslation();

  const schema = useGenerateSchema();

  const { handleSubmit, control, watch, clearErrors, reset } = useForm<
    z.infer<typeof schema>
  >({
    resolver: zodResolver(schema),
    defaultValues: {
      note: prefilledText || '',
    },
  });

  useEffect(() => {
    if (prefilledText !== undefined) {
      reset({ note: prefilledText || '' });
    }
  }, [prefilledText, reset]);

  return (
    <Modal
      isVisible={isVisible}
      toggleVisibility={() => {
        toggleVisibility();
        clearErrors();
      }}
    >
      <form
        onSubmit={handleSubmit(({ note }) => {
          onSubmit(note);
          reset({ note: '' });
        })}
      >
        <div className="flex flex-col p-8 min-w-[500px]">
          <Header className="pb-2 text-left">{title}</Header>
          {children && <div>{children}</div>}
          <Controller
            name="note"
            control={control}
            render={({ field, fieldState: { error } }) => {
              return (
                <div className="flex flex-col gap-2">
                  <textarea
                    {...field}
                    placeholder={t('notes.placeholder')}
                    className="form-input w-full p-2 rounded-md"
                    rows={6}
                    maxLength={maxLength ?? NOTE_MAX_LENGTH}
                  />
                  {error?.message && (
                    <span className="mr-auto text-red-500">
                      {error.message}
                    </span>
                  )}
                </div>
              );
            }}
          />
          <div className="mt-2 ml-auto">
            <span
              className={`${
                (watch('note') ?? '').length > (maxLength ?? NOTE_MAX_LENGTH) &&
                'text-red-600'
              }`}
            >{`${(watch('note') ?? '').length}/${
              maxLength ?? NOTE_MAX_LENGTH
            }`}</span>
          </div>

          <div className="mt-2 ml-auto">
            <ButtonWithLoading
              text={submitText}
              type="submit"
              loading={isLoading}
            />
          </div>
        </div>
      </form>
    </Modal>
  );
};
