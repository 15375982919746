import { Table as FlowbiteTable } from 'flowbite-react';

type GenericRow = { id: string };

type Props<T> = {
  label: string;
  columns: string[];
  rows: T[];
};

export const Table = <T extends GenericRow>({
  label,
  columns,
  rows,
}: Props<T>) => {
  return (
    <div className="flex flex-col gap-2">
      <span className="my-2 text-xl font-semibold">{label}</span>
      <div className="overflow-x-auto">
        <FlowbiteTable>
          <FlowbiteTable.Head>
            {columns.map((column) => (
              <FlowbiteTable.HeadCell key={column}>
                {column}
              </FlowbiteTable.HeadCell>
            ))}
          </FlowbiteTable.Head>
          <FlowbiteTable.Body>
            {rows.map((row) => {
              return (
                <FlowbiteTable.Row
                  className="dark:border-gray-700 dark:bg-gray-800 dark:text-white font-medium text-gray-900 bg-white"
                  key={row.id}
                >
                  {Object.entries(row).map(([key, value], i) => {
                    if (key === 'id') {
                      return null;
                    }

                    return (
                      <FlowbiteTable.Cell
                        className="border-2 border-gray-100"
                        key={i}
                      >
                        {value}
                      </FlowbiteTable.Cell>
                    );
                  })}
                </FlowbiteTable.Row>
              );
            })}
          </FlowbiteTable.Body>
        </FlowbiteTable>
      </div>
    </div>
  );
};
