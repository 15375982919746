import { Auth } from 'aws-amplify';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { z } from 'zod';
import NotyfContext from '~/context/NotyfContext';
import { BasicUserSchema } from '../dtos/BasicUserDto';

type UnblockRemoveWarningsRequestData = {
  startDate: string;
  endDate: string;
  operator: string;
  note: string;
};

export type UnblockRemoveWarningsResponseData = {
  usersUnblocked: z.infer<typeof BasicUserSchema>[];
  usersWithDecreasedWarnings: z.infer<typeof BasicUserSchema>[];
};

const unblockRemoveWarningsCustomersMutation = async ({
  startDate,
  endDate,
  operator,
  note,
}: UnblockRemoveWarningsRequestData) => {
  const session = await Auth.currentSession();

  const jwtToken = session.getIdToken().getJwtToken();

  return fetch(
    `${import.meta.env.VITE_REACT_APP_DOT_API_ENDPOINT}/warnings/decrease`,
    {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        startDate,
        endDate,
        operator,
        unblockNote: note,
      }),
    }
  ).then((res) => {
    if (res.ok) {
      return res.json();
    }

    throw new Error();
  });
};

export const useUnblockRemoveWarningsCustomersMutation = () => {
  const notyf = useContext(NotyfContext);
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const unblockRemoveWarningsCustomers = useMutation<
    UnblockRemoveWarningsResponseData,
    Error,
    UnblockRemoveWarningsRequestData
  >(unblockRemoveWarningsCustomersMutation, {
    onSuccess: (data) => {
      const affectedCustomers =
        data.usersUnblocked.length + data.usersWithDecreasedWarnings.length;
      notyf.success(
        `${t(
          'disturbance-cleanup.disturbance-cleanup-success'
        )} ${affectedCustomers}`
      );
      queryClient.invalidateQueries({ queryKey: ['disturbance_cleanup'] });
    },
    onError: () => {
      notyf.error(t('status.error'));
    },
  });

  return { unblockRemoveWarningsCustomers };
};
