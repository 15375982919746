import { Auth } from 'aws-amplify';
import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { z } from 'zod';
import NotyfContext from '~/context/NotyfContext';
import { BasicUserSchema } from '../dtos/BasicUserDto';

type DecreaseCustomerWarningRequestData = {
  customerId: number;
  note: string;
};

export type DecreaseCustomerWarningResponseData = {
  newWarningCount: number;
};

const decreaseCustomerWarningMutation = async ({
  customerId,
  note,
}: DecreaseCustomerWarningRequestData) => {
  const session = await Auth.currentSession();

  const jwtToken = session.getIdToken().getJwtToken();

  return fetch(
    `${
      import.meta.env.VITE_REACT_APP_DOT_API_ENDPOINT
    }/warnings/${customerId}/decrease`,
    {
      headers: {
        Authorization: `Bearer ${jwtToken}`,
        'Content-Type': 'application/json',
      },
      method: 'POST',
      body: JSON.stringify({
        note,
      }),
    }
  ).then((res) => {
    if (res.ok) {
      return res.json();
    }

    throw new Error();
  });
};

export const useDecreaseCustomerWarningMutation = () => {
  const notyf = useContext(NotyfContext);
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  const decreaseCustomerWarning = useMutation<
    DecreaseCustomerWarningResponseData,
    Error,
    DecreaseCustomerWarningRequestData
  >(decreaseCustomerWarningMutation, {
    onSuccess: (data) => {
      notyf.success(t('customer.decrease-warning-success'));
      queryClient.invalidateQueries({ queryKey: ['user'] });
    },
    onError: () => {
      notyf.error(t('status.error'));
    },
  });

  return { decreaseCustomerWarning };
};
