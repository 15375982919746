import type { BlockadesTableProps } from '.';
import type { UserDto } from '~/lib/dtos/UserDto';

export const createBlockadesData = ({
  temporarilyBlockedUser,
  temporaryBlockHistories,
  permanentBlockHistories,
  permanentlyBlockedUser,
  decreasedWarningsHistory,
}: UserDto['user']): BlockadesTableProps['blockades'] => {
  const blockadesData: BlockadesTableProps['blockades'] = [];

  for (const temporaryBlock of temporaryBlockHistories) {
    blockadesData.push({
      type: 'temporary',
      id: `${temporaryBlock.id.value}-temporary`,
      user: temporaryBlock.blockedBy.value,
      note: temporaryBlock.note.value,
      createdAt: temporaryBlock.blockCreatedAt,
      blockadeStartAt: temporaryBlock.blockCreatedAt,
      blockadeEndAt: temporaryBlock.blockadeExpirationDate,
      blockReason: temporaryBlock.blockReason,
    });

    if (temporaryBlock.unblockNote.value) {
      blockadesData.push({
        type: 'unblock',
        id: `${temporaryBlock.id.value}-temporary-unblock`,
        createdAt: temporaryBlock.createdAt,
        blockadeStartAt: null,
        blockadeEndAt: null,
        note: temporaryBlock.unblockNote.value,
        user: temporaryBlock.unblockedBy.value,
        blockReason: 'DEFAULT',
      });
    }
  }

  for (const permanentBlock of permanentBlockHistories) {
    blockadesData.push({
      type: 'permanent',
      id: `${permanentBlock.id.value}-permanent`,
      user: permanentBlock.blockedBy.value,
      note: permanentBlock.note.value,
      createdAt: permanentBlock.blockCreatedAt,
      blockadeStartAt: permanentBlock.blockCreatedAt,
      blockadeEndAt: permanentBlock.blockadeExpirationDate,
      blockReason: permanentBlock.blockReason,
    });

    if (permanentBlock.unblockNote.value) {
      blockadesData.push({
        type: 'unblock',
        id: `${permanentBlock.id.value}-permanent-unblock`,
        createdAt: permanentBlock.createdAt,
        blockadeStartAt: null,
        blockadeEndAt: null,
        note: permanentBlock.unblockNote.value,
        user: permanentBlock.unblockedBy.value,
        blockReason: 'DEFAULT',
      });
    }
  }

  for (const decreasedWarning of decreasedWarningsHistory) {
    blockadesData.push({
      type: 'decreased-warning',
      id: `${decreasedWarning.id.value}-decreased-warning`,
      user: decreasedWarning.decreasedBy,
      note: decreasedWarning.note.value,
      createdAt: decreasedWarning.createdAt,
      blockadeStartAt: null,
      blockadeEndAt: null,
      blockReason: 'DEFAULT',
    });
  }

  if (permanentlyBlockedUser) {
    blockadesData.push({
      type: 'permanent',
      id: `${permanentlyBlockedUser.id.value}-current-permanent`,
      createdAt: permanentlyBlockedUser.createdAt,
      blockadeStartAt: permanentlyBlockedUser.createdAt,
      blockadeEndAt: permanentlyBlockedUser.blockadeExpirationDate,
      note: permanentlyBlockedUser.note.value,
      user: permanentlyBlockedUser.blockedBy.value,
      blockReason: permanentlyBlockedUser.blockReason,
    });
  }

  if (temporarilyBlockedUser) {
    blockadesData.push({
      type: 'temporary',
      id: `${temporarilyBlockedUser.id.value}-current-temporary`,
      createdAt: temporarilyBlockedUser.createdAt,
      blockadeStartAt: temporarilyBlockedUser.createdAt,
      blockadeEndAt: temporarilyBlockedUser.blockadeExpirationDate,
      note: temporarilyBlockedUser.note.value,
      user: temporarilyBlockedUser.blockedBy.value,
      blockReason: temporarilyBlockedUser.blockReason,
    });
  }

  return blockadesData.sort((a, b) => b.createdAt.localeCompare(a.createdAt));
};
